<template>
  <el-dialog
    :visible.sync="$store.state.globalShare.replyImPopupStatus"
    custom-class="shareDialog"
    center
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <div class="popupHeaderContainer">
      <div
        class="headerInnerContainer"
        :style="{
          justifyContent: listType === 0 ? 'flex-end' : 'space-between',
        }"
      >
        <div
          class="headerButton"
          @click.stop="showlistTypeOne"
          v-if="listType !== 0"
        >
          <span class="iconfont_Me icon-left iconStyle1"></span>
        </div>
        <span
          class="iconfont_Me titleIconStyle"
          :class="
            listType === 0
              ? 'icon-share'
              : listType === 1
              ? 'icon-Friends-fill'
              : 'icon-group-fill'
          "
        ></span>
        <div class="headerButton" @click.stop="closePopup">
          <span class="iconfont_Me icon-x iconStyle2"></span>
        </div>
      </div>
    </div>
    <div class="popupContainer">
      <div class="containerBody" v-show="listType === 0">
        <div class="searchInputOuter">
          <div class="searchInputBox">
            <el-input
              class="inputStyle"
              v-model="searchKey"
              :placeholder="searchPlaceholder"
              @keydown.enter.native="triggerSearchEvent"
              @input="triggerSearchEvent"
            ></el-input>
          </div>
        </div>
        <div
          class="recentCommunicationsContainer"
          v-scroll="{
            distance: 70,
            onBottom: loadMoreRecentCommunicationsData,
          }"
        >
          <template v-if="recentCommunications.length !== 0">
            <RecentCommunication
              v-for="(itemInfo, index) in recentCommunications"
              :key="itemInfo.contactId + index"
              :itemInfo="itemInfo"
              @selectThisElement="operatingHasSelectedItem"
            ></RecentCommunication>
          </template>
          <div v-else class="placeholderMap">
            <PlaceholderMap :placeholderType="2"></PlaceholderMap>
          </div>
          <div
            class="loadingBlock"
            v-loading="recentCommunicationLoading"
            element-loading-spinner="el-icon-loading"
            element-loading-background="#FFFFFF"
          ></div>
        </div>
      </div>
      <div class="containerFooter">
        <transition name="fade">
          <div
            class="hasSelectedDisplayContainer"
            v-if="haveBeenSelectedArr.length > 0"
          >
            <div
              class="scrollButton"
              :style="{ left: '9px' }"
              @click.stop="scrollLeftButton"
            >
              <span class="iconfont_Me icon-left iconStyle"></span>
            </div>
            <div
              class="scrollButton"
              :style="{ right: '9px' }"
              @click.stop="scrollRightButton"
            >
              <span class="iconfont_Me icon-right iconStyle"></span>
            </div>
            <div class="hasSelectedDisplayArea" ref="hasSelectedDisplayArea">
              <div
                class="hasSelectedItem"
                v-for="selectedItem in haveBeenSelectedArr"
                :key="selectedItem.contactId + selectedItem.isSelected"
                :title="selectedItem.name"
              >
                <div
                  class="deleteButton"
                  @click.stop="operatingHasSelectedItem(selectedItem)"
                >
                  <span class="iconfont_Me icon-x iconStyle"></span>
                </div>
                <Avatar
                  :path="selectedItem.profilePic"
                  :international="
                    selectedItem.countryCode !== ''
                      ? Number(selectedItem.countryCode)
                      : 0
                  "
                  :isGroupHeader="
                    selectedItem.chatType == 'groupChat' ||
                      selectedItem.isGroupContacts
                  "
                  :disableClick="true"
                ></Avatar>
              </div>
            </div>
          </div>
        </transition>
        <div
          class="footerButton"
          v-loading="$store.state.globalShare.listenToReply"
          element-loading-spinner="el-icon-loading"
          element-loading-background="#FFFFFF"
        >
          <div
            class="buttonStyle"
            :class="
              haveBeenSelectedArr.length !== 0
                ? 'commomButtonHover'
                : 'disableStyle'
            "
            @click.stop="startShare"
          >
            <span class="iconfont_Me icon-check iconStyle"></span>
            <span v-if="haveBeenSelectedArr.length > 0" class="buttonFont"
              >({{ haveBeenSelectedArr.length }})</span
            >
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import {
  getRecentCommunications,
  getGroupContactList,
  getFriendList,
} from "@/api/newVersion";
import RecentCommunication from "../shareDialog/contactPersonItem.vue";
import Avatar from "../avatarDisplay";
import GroupList from "../shareDialog/groupList";
import FriendsList from "../shareDialog/friendsList";
import PlaceholderMap from "../placeholderMap";
export default {
  components: {
    RecentCommunication,
    GroupList,
    FriendsList,
    Avatar,
    PlaceholderMap,
  },
  data() {
    return {
      // 最近联系人列表加载loading
      recentCommunicationLoading: false,
      // 搜索条件字段
      searchKey: "",
      searchPlaceholder: "\ue681",
      // 当前展示的列表类型（最近联系人列表0，好友列表1，群列表2）
      listType: 0,
      // 群组列表集合
      groupList: [],
      // 好友列表集合
      friendsList: [],
      // 最近通信人和群列表集合
      recentCommunications: [],
      // 是否可以继续触发加载最近联系对象列表
      loadMoreRecentCommunicationsDisabled: false,
      // 最近通信人列表第一页
      recentCommunicationsPage: 1,
      // 最近通信人列表一页展示数量
      recentCommunicationsPageSize: 10,
      // 用于通知群列表和好友列表加载数据
      readyToLoadData: false,
    };
  },
  watch: {
    // 监听是否分享动作是否已完成
    "$store.state.globalShare.listenToReply": {
      handler(val, old) {
        if (!val && val !== old) {
          this.$message({
            type: "success",
            message: this.$t("successfulSharing"),
          });
          this.$nextTick(() => {
            this.closePopup();
          });
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getRecentCommunications();
    this.$nextTick(() => {
      this.readyToLoadData = true;
    });
  },
  computed: {
    // 已被选中的人或群
    haveBeenSelectedArr() {
      let haveBeenSelectedArr = [];
      // 合并已选中的最近联系对象
      haveBeenSelectedArr = haveBeenSelectedArr.concat(
        this.recentCommunications.filter((e) => e.isSelected)
      );
      // 合并已选中的群组对象
      haveBeenSelectedArr = haveBeenSelectedArr.concat(
        this.groupList.filter((e) => e.isSelected)
      );
      // 合并已选中的好友对象
      haveBeenSelectedArr = haveBeenSelectedArr.concat(
        this.friendsList.filter((e) => e.isSelected)
      );
      // 过滤掉数组中重复的对象
      haveBeenSelectedArr = this.filterAnArrayOfObjects(
        haveBeenSelectedArr,
        "contactId"
      );
      return haveBeenSelectedArr;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  methods: {
    // 点击往左滑动按钮
    scrollLeftButton() {
      this.$refs.hasSelectedDisplayArea.scrollLeft -= 48;
    },
    // 点击往右滑动按钮
    scrollRightButton() {
      this.$refs.hasSelectedDisplayArea.scrollLeft += 48;
    },
    // 展示最近联系人列表
    showlistTypeOne() {
      this.listType = 0;
    },
    // 弹窗关闭前执行
    closePopup() {
      // 一旦开始分享，就先禁止关闭分享弹窗，重置分享数据
      if (this.$store.state.globalShare.listenToReply) {
        return;
      }
      this.$store.dispatch("commitCloseReplyPopup");
    },
    // 触发搜索最近通讯人
    triggerSearchEvent: _.debounce(function() {
      this.recentCommunications = [];
      this.recentCommunicationsPage = 1;
      this.loadMoreRecentCommunicationsDisabled = false;
      this.getRecentCommunications()
        .then(() => {
          this.loadMoreRecentCommunicationsDisabled = false;
        })
        .catch(() => {
          this.loadMoreRecentCommunicationsDisabled = true;
        });
    }, 500),
    // 展示好友列表
    showTheFriendList() {
      this.listType = 1;
    },
    // 展示群列表
    showTheGroupList() {
      this.listType = 2;
    },
    // 开始分享
    startShare() {
      // 一旦开始分享，就先禁止再次点击分享
      if (this.$store.state.globalShare.listenToReply) {
        return;
      }
      if (this.haveBeenSelectedArr.length !== 0) {
        this.$store.dispatch("commitStartIMReply", this.haveBeenSelectedArr);
      }
    },
    // 操作数据的选中状态
    operatingHasSelectedItem(item) {
      // 一旦开始分享，就先禁止操作数据
      if (this.$store.state.globalShare.listenToReply) {
        return;
      }
      // 当数据是来自于最近联系人列表时,取消列表中选中状态
      // 最近联系人或群的数据(因为群列表数据或者好友数据可能会出现最近联系人或群列表中，所以每次变更好友或者群组的选中状态都要将最近联系列表中的状态一起改变)
      this.recentCommunications = this.selectedPublicMethod(
        this.recentCommunications,
        item.contactId
      );
      this.groupList = this.selectedPublicMethod(
        this.groupList,
        item.contactId
      );
      this.friendsList = this.selectedPublicMethod(
        this.friendsList,
        item.contactId
      );
      this.$forceUpdate();
    },
    // 选中与取消选中的公共方法
    selectedPublicMethod(handleArr, contactId) {
      let newArr = handleArr.map((e) => {
        if (e.contactId === contactId) {
          e.isSelected = !e.isSelected;
        }
        return e;
      });
      return newArr;
    },
    // 开始加载好友列表数据
    async getFriendList(successFunction, errorFunction) {
      let result = await getFriendList();
      if (result.code == 1) {
        if (result.data.list.length !== 0) {
          let handleData = result.data.list.map((e) => {
            e.isSelected = false;
            // 这个主要用于后续判断是否是来自于最近群组列表的数据，以便区分最近联系人、好友、群的数据
            e.isFriendContacts = true;
            // 好友ID就是聊天室的ID，所以直接将好友ID设置为聊天室ID
            e.contactId = e.provider_id;
            // 赋值这个主要是为了兼容顶部选中队列的渲染
            e.profilePic = e.avatar;
            e.countryCode = e.country;
            e.chatType = "chat";
            // 判断加载的数据是否已经被选中过
            if (
              this.haveBeenSelectedArr.length !== 0 &&
              this.haveBeenSelectedArr.some(
                (element) => element.contactId === e.contactId
              )
            ) {
              e.isSelected = true;
            }
            return e;
          });
          const self = {
            avatar: this.userInfo.avatar,
            chatType: "chat",
            contactId: this.userInfo.id,
            country: this.userInfo.country,
            gender: this.userInfo.gender,
            isFriendContacts: true,
            isSelected: false,
            manager_role: this.userInfo.manager_role,
            name: this.userInfo.name,
            name_first_spell: "self",
            profilePic: this.userInfo.avatar,
            profile_photo: this.userInfo.avatar,
            provider_id: this.userInfo.id,
            tags: [],
            uc_id: this.userInfo.uc_id,
            userId: this.userInfo.id,
            userName: this.userInfo.name,
            vipStatus: this.userInfo.vipStatus,
          };
          this.friendsList = [self];
          this.friendsList = this.friendsList.concat(handleData);
          successFunction();
        } else {
          errorFunction();
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
        errorFunction();
      }
    },
    // 开始加载群数据（请求写在这里，不写在群组列表组件里是因为方便删除统计选项时可以直接操作groupList）
    async getGroupContactList(
      groupSearchParams,
      successFunction,
      errorFunction
    ) {
      let params = {
        page: groupSearchParams.groupContactListPage,
        pageSize: groupSearchParams.groupContactListPageSize,
        searchTerm: groupSearchParams.groupSearchKey,
      };
      let result = await getGroupContactList(params);
      // 每次请求第一页都清空列表数据
      if (groupSearchParams.groupContactListPage === 1) {
        this.groupList = [];
      }
      if (result.code == 200) {
        if (result.data.data.length !== 0) {
          let handleData = result.data.data.map((e) => {
            e.isSelected = false;
            // 这个主要用于后续判断是否是来自于最近群组列表的数据，以便区分最近联系人、好友、群的数据
            e.isGroupContacts = true;
            // 判断加载的数据是否已经被选中过
            if (
              this.haveBeenSelectedArr.length !== 0 &&
              this.haveBeenSelectedArr.some(
                (element) => element.contactId === e.contactId
              )
            ) {
              e.isSelected = true;
            }
            return e;
          });
          this.groupList = this.groupList.concat(handleData);
          successFunction();
        } else {
          errorFunction();
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
        errorFunction();
      }
    },
    // 加载更多最近联系的对象
    loadMoreRecentCommunicationsData() {
      if (!this.loadMoreRecentCommunicationsDisabled) {
        this.loadMoreRecentCommunicationsDisabled = true;
        this.recentCommunicationsPage++;
        this.getRecentCommunications()
          .then(() => {
            this.loadMoreRecentCommunicationsDisabled = false;
          })
          .catch(() => {
            this.loadMoreRecentCommunicationsDisabled = true;
          });
      }
    },
    // 获取最近通信的人和群列表
    getRecentCommunications() {
      this.recentCommunicationLoading = true;
      return new Promise(async (resolve, reject) => {
        let params = {
          page: this.recentCommunicationsPage,
          pageSize: this.recentCommunicationsPageSize,
          searchTerm: this.searchKey,
        };
        let result = await getRecentCommunications(params);
        this.recentCommunicationLoading = false;
        if (result.code == 200) {
          if (result.data.data.length !== 0) {
            let handleData = result.data.data.map((e) => {
              e.isSelected = false;
              // 这个主要用于后续判断是否是来自于最近联系人列表的数据，以便区分最近联系人、好友、群的数据
              e.isRecentContacts = true;
              // 判断加载的数据是否已经被选中过
              if (
                this.haveBeenSelectedArr.length !== 0 &&
                this.haveBeenSelectedArr.some(
                  (element) => element.contactId === e.contactId
                )
              ) {
                e.isSelected = true;
              }
              return e;
            });
            handleData = handleData.filter((item) => {
              return item.contactId !== "";
            });
            this.recentCommunications = this.recentCommunications.concat(
              handleData
            );
            resolve("success");
          } else {
            if (this.recentCommunicationsPage > 1) {
              this.recentCommunicationsPage--;
            }
            resolve("success");
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
          resolve("success");
        }
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translate3d(0, 60px, 0);
}

.shareDialog {
  .popupHeaderContainer {
    width: 100%;
    height: 70px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .headerInnerContainer {
      width: 100%;
      height: 35px;
      position: relative;
      box-sizing: border-box;
      padding: 0 15px;
      display: flex;
      align-items: center;

      .headerButton {
        width: 24px;
        height: 24px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        .iconStyle1 {
          color: #373737;
          font-size: 24px;
        }

        .iconStyle2 {
          color: #CCCCCC;
          font-size: 24px;
        }
      }

      .titleIconStyle {
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        color: #373737;
        font-size: 32px;
        position: absolute;
      }
    }
  }

  .popupContainer {
    width: 100%;
    flex: 1;
    min-height: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;

    .containerBody {
      flex: 1;
      width: 100%;
      min-height: 0;
      box-sizing: border-box;
      padding: 0 15px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: flex-start;

      .searchInputOuter {
        width: 100%;
        height: 36px;
        flex-shrink: 0;
        margin-bottom: 15px;
        padding: 0 15px;

        .searchInputBox {
          width: 100%;
          height: 36px;
          border-radius: 18px;
          background: #F0F0F0;
          overflow: hidden;
          box-sizing: border-box;
          padding: 0 15px;

          .inputStyle {
            width: 100%;
            height: 36px;
            border-radius: 18px;
          }
        }
      }

      .recentCommunicationsContainer {
        flex: 1;
        width: 100%;
        min-height: 0;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
          background: rgba(100,100,100,0.2);
        }

        .placeholderMap {
          width: 100%;
          height: 150px;
        }

        .loadingBlock{
          width: 100%;
          height: 50px;
        }

        .classificationButtonStyle {
          width: 100%;
          height: 70px;
          padding: 0 15px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: space-between;
          transition: background 0.3s;

          &:hover {
            background: #F0F0F0;
          }

          .buttonIcon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: #33CC66;
            display: flex;
            align-items: center;
            justify-content: center;

            .iconStyle {
              font-size: 30px;
              color: #FFFFFF;
            }
          }

          .jumpIcon {
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;

            .iconStyle {
              font-size: 22px;
              color: #666666;
            }
          }
        }

        .loadingContainer {
          height: 70px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .containerFooter {
      width: 100%;
      flex-shrink: 0;
      max-height: 120px;
      min-height: 65px;

      .hasSelectedDisplayContainer {
        width: 100%;
        height: 55px;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0 15px;
        position: relative;
        background: #F2FCF8;

        &:hover {
          .scrollButton {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: block;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            transform: translate3d(0, -50%, 0);
            z-index: 2;
            cursor: pointer;

            .iconStyle {
              font-size: 14px;
              color: #ffffff;
            }
          }
        }

        .scrollButton {
          display: none;
        }

        .hasSelectedDisplayArea {
          width: 100%;
          height: 100%;
          text-align: left;
          overflow-y: hidden;
          overflow-x: auto;
          white-space: nowrap;
          position: relative;

          &::-webkit-scrollbar {
            height: 2px;
          }

          .hasSelectedItem {
            width: 40px;
            height: 40px;
            margin: 7.5px 8px 0 0;
            display: inline-block;
            position: relative;

            &:hover {
              .deleteButton {
                top: 0;
                right: 0;
                width: 15px;
                height: 15px;
                z-index: 2;
                display: block;
                position: absolute;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.3);
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                .iconStyle {
                  font-size: 12px;
                  color: #FFFFFF;
                }
              }
            }

            .deleteButton {
              display: none;
            }
          }
        }
      }

      .footerButton {
        width: 100%;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;

        .buttonStyle {
          width: 120px;
          height: 35px;
          display: flex;
          cursor: pointer;
          border-radius: 4px;
          background: #d2d2d2;
          align-items: center;
          user-select: none;
          justify-content: center;
          transition: box-shadow 0.2s;

          &:active {
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
          }

          .iconStyle {
            color: #FFFFFF;
            font-size: 22px;
          }

          .buttonFont {
            color: #FFFFFF;
            font-size: 12px;
          }
        }
        .disableStyle {
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
<style lang="stylus">
.shareDialog {
  margin-top: 0 !important;
  height: 700px !important;
  max-height: 90vh !important;
  width: 450px !important;
  display: flex !important;
  flex-flow: column !important;
  align-items: center !important;
  justify-content: flex-start !important;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    width: 100%;
    height: 100%;
    padding: 0 !important;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;

    .popupContainer {
      .el-loading-spinner i {
        font-size: 24px !important;
      }

      .footerButton {
        .el-loading-spinner {
          margin-top: -11px;
        }
      }

      .containerBody {
        .searchInputBox {
          .el-input__inner {
            height: 36px !important;
            border: none !important;
            font-family: 'iconfont_Me';
            caret-color: #33CC66;
            padding: 0 !important;
            font-size: 14px !important;
            color: #333333 !important;
            background: #F0F0F0 !important;

            &::-webkit-input-placeholder {
              font-size: 22px;
              user-select: none;
              transform: translate(0, 12%);
              -webkit-transform: translate(0, 12%);
            }
          }
        }
      }
    }
  }
}
</style>

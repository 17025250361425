var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.$store.state.globalShare.replyImPopupStatus,
        "custom-class": "shareDialog",
        center: "",
        "append-to-body": true,
        "destroy-on-close": true,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.globalShare,
            "replyImPopupStatus",
            $event
          )
        }
      }
    },
    [
      _c("div", { staticClass: "popupHeaderContainer" }, [
        _c(
          "div",
          {
            staticClass: "headerInnerContainer",
            style: {
              justifyContent: _vm.listType === 0 ? "flex-end" : "space-between"
            }
          },
          [
            _vm.listType !== 0
              ? _c(
                  "div",
                  {
                    staticClass: "headerButton",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.showlistTypeOne.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("span", {
                      staticClass: "iconfont_Me icon-left iconStyle1"
                    })
                  ]
                )
              : _vm._e(),
            _c("span", {
              staticClass: "iconfont_Me titleIconStyle",
              class:
                _vm.listType === 0
                  ? "icon-share"
                  : _vm.listType === 1
                  ? "icon-Friends-fill"
                  : "icon-group-fill"
            }),
            _c(
              "div",
              {
                staticClass: "headerButton",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.closePopup.apply(null, arguments)
                  }
                }
              },
              [_c("span", { staticClass: "iconfont_Me icon-x iconStyle2" })]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "popupContainer" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.listType === 0,
                expression: "listType === 0"
              }
            ],
            staticClass: "containerBody"
          },
          [
            _c("div", { staticClass: "searchInputOuter" }, [
              _c(
                "div",
                { staticClass: "searchInputBox" },
                [
                  _c("el-input", {
                    staticClass: "inputStyle",
                    attrs: { placeholder: _vm.searchPlaceholder },
                    on: { input: _vm.triggerSearchEvent },
                    nativeOn: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.triggerSearchEvent.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.searchKey,
                      callback: function($$v) {
                        _vm.searchKey = $$v
                      },
                      expression: "searchKey"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "scroll",
                    rawName: "v-scroll",
                    value: {
                      distance: 70,
                      onBottom: _vm.loadMoreRecentCommunicationsData
                    },
                    expression:
                      "{\n          distance: 70,\n          onBottom: loadMoreRecentCommunicationsData,\n        }"
                  }
                ],
                staticClass: "recentCommunicationsContainer"
              },
              [
                _vm.recentCommunications.length !== 0
                  ? _vm._l(_vm.recentCommunications, function(itemInfo, index) {
                      return _c("RecentCommunication", {
                        key: itemInfo.contactId + index,
                        attrs: { itemInfo: itemInfo },
                        on: { selectThisElement: _vm.operatingHasSelectedItem }
                      })
                    })
                  : _c(
                      "div",
                      { staticClass: "placeholderMap" },
                      [_c("PlaceholderMap", { attrs: { placeholderType: 2 } })],
                      1
                    ),
                _c("div", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.recentCommunicationLoading,
                      expression: "recentCommunicationLoading"
                    }
                  ],
                  staticClass: "loadingBlock",
                  attrs: {
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-background": "#FFFFFF"
                  }
                })
              ],
              2
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "containerFooter" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.haveBeenSelectedArr.length > 0
                ? _c("div", { staticClass: "hasSelectedDisplayContainer" }, [
                    _c(
                      "div",
                      {
                        staticClass: "scrollButton",
                        style: { left: "9px" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.scrollLeftButton.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "iconfont_Me icon-left iconStyle"
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "scrollButton",
                        style: { right: "9px" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.scrollRightButton.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "iconfont_Me icon-right iconStyle"
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        ref: "hasSelectedDisplayArea",
                        staticClass: "hasSelectedDisplayArea"
                      },
                      _vm._l(_vm.haveBeenSelectedArr, function(selectedItem) {
                        return _c(
                          "div",
                          {
                            key:
                              selectedItem.contactId + selectedItem.isSelected,
                            staticClass: "hasSelectedItem",
                            attrs: { title: selectedItem.name }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "deleteButton",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.operatingHasSelectedItem(
                                      selectedItem
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  staticClass: "iconfont_Me icon-x iconStyle"
                                })
                              ]
                            ),
                            _c("Avatar", {
                              attrs: {
                                path: selectedItem.profilePic,
                                international:
                                  selectedItem.countryCode !== ""
                                    ? Number(selectedItem.countryCode)
                                    : 0,
                                isGroupHeader:
                                  selectedItem.chatType == "groupChat" ||
                                  selectedItem.isGroupContacts,
                                disableClick: true
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.$store.state.globalShare.listenToReply,
                    expression: "$store.state.globalShare.listenToReply"
                  }
                ],
                staticClass: "footerButton",
                attrs: {
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "#FFFFFF"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "buttonStyle",
                    class:
                      _vm.haveBeenSelectedArr.length !== 0
                        ? "commomButtonHover"
                        : "disableStyle",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.startShare.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("span", {
                      staticClass: "iconfont_Me icon-check iconStyle"
                    }),
                    _vm.haveBeenSelectedArr.length > 0
                      ? _c("span", { staticClass: "buttonFont" }, [
                          _vm._v(
                            "(" + _vm._s(_vm.haveBeenSelectedArr.length) + ")"
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }